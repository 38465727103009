import { useState } from 'react';
import classNames from 'classnames';
import {EASY_WORDS, MEDIUM_WORDS, HARD_WORDS} from './words'
import hangman0 from './hangman_0.svg'
import hangman1 from './hangman_1.svg'
import hangman2 from './hangman_2.svg'
import hangman3 from './hangman_3.svg'
import hangman4 from './hangman_4.svg'
import hangman5 from './hangman_5.svg'
import hangman6 from './hangman_6.svg'
import hangman7 from './hangman_7.svg'

let gameTimeInterval;

function App() {
  const [word, setWord] = useState('');
  const [wrongLetters, setWrongLetters] = useState([]);
  const [rightLetters, setRightLetters] = useState([]);
  const [gameTime, setGameTime] = useState(0);

  const pictures = [hangman0, hangman1, hangman2, hangman3, hangman4, hangman5, hangman6, hangman7];
  const keyMap = [
    [
      {key: 'q', code: 81},
      {key: 'w', code: 87},
      {key: 'e', code: 69},
      {key: 'r', code: 82},
      {key: 't', code: 84},
      {key: 'y', code: 89},
      {key: 'u', code: 85},
      {key: 'i', code: 73},
      {key: 'o', code: 79},
      {key: 'p', code: 80},
      {key: 'å', code: 229},
    ],
    [
      {key: 'a', code: 65},
      {key: 's', code: 83},
      {key: 'd', code: 68},
      {key: 'f', code: 70},
      {key: 'g', code: 71},
      {key: 'h', code: 72},
      {key: 'j', code: 74},
      {key: 'k', code: 75},
      {key: 'l', code: 76},
      {key: 'æ', code: 230},
      {key: 'ø', code: 248},
    ],
    [
      {key: 'z', code: 90},
      {key: 'x', code: 88},
      {key: 'c', code: 67},
      {key: 'v', code: 86},
      {key: 'b', code: 66},
      {key: 'n', code: 78},
      {key: 'm', code: 77}
    ],
  ]

  const startGame = (difficulty) => {
    const difficultyWords = {
      easy: EASY_WORDS,
      medium: MEDIUM_WORDS,
      hard: HARD_WORDS
    }

    setGameTime(0);
    const word = difficultyWords[difficulty][Math.floor(Math.random() * difficultyWords[difficulty].length)];
    setWord(word);
    setWrongLetters([]);
    setRightLetters([]);

    gameTimeInterval = setInterval(() => {
      setGameTime(gameTime => gameTime + 1);
    }, 1000);
  }

  const resetGame = () => {
    setWord('');
    setWrongLetters([]);
    setRightLetters([]);
    setGameTime(0);
    clearInterval(gameTimeInterval);
  }

  const handleGuess = (letter) => {
    if (!word.includes(letter) && !wrongLetters.includes(letter)) {
      const newWrongLetters = [...wrongLetters, letter];
      setWrongLetters(newWrongLetters);

      if (newWrongLetters.length === 7) {
        alert(`Du tabte! Ordet var: ${word}`);
        resetGame();
      }

    } else if (word.includes(letter) && !rightLetters.includes(letter)) {
      const newRightLetters = [...rightLetters, letter];
      setRightLetters(newRightLetters);
      const wordArray = word.split('');

      if (wordArray.filter(letter => !newRightLetters.includes(letter)).length === 0) {
        const wrongLettersCount = wrongLetters.length;
        alert(`Tillykke, du har gættet ordet! Ordet var: ${word}. Du brugte ${wrongLettersCount} forkerte gæt.`);
        resetGame();
      }
    }
  }

  const formatGameTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  return (
    <div className="">
      {word === '' ? (
        <div className='bg-white p-4 h-screen flex flex-col md:flex-row gap-8 max-w-none md:max-w-5xl mx-auto'>
          <div className="">
            <h1 className='text-3xl font-bold text-center mb-8'>Galgeleg!</h1>
            <img src={hangman7} alt="Galgeleg" className='w-1/2 mx-auto'/>
          </div>
          <div className="mb-16 md:mt-20">
            <h2 className='text-xl font-bold text-center mb-4'>Vælg sværhedsgrad</h2>
            <button onClick={() => {startGame('easy')}}
            className='border border-black bg-white uppercase rounded-md py-1.5 w-full'>
              Nem
            </button>
            <button onClick={() => {startGame('medium')}}
            className='border border-black bg-white uppercase rounded-md py-1.5 w-full mt-4'>
              Middel
            </button>
            <button onClick={() => {startGame('hard')}}
            className='border border-black bg-white uppercase rounded-md py-1.5 w-full mt-4'>
              Svær
            </button>
          </div>
          
        </div>
      ) : (
        <div className='bg-white p-4 h-screen flex flex-col md:flex-row gap-4 max-w-none md:max-w-5xl mx-auto'>
          <div className="w-full">
            <h1 className='text-3xl font-bold text-center mb-4'>Galgeleg!</h1>
            <p className='text-center font-bold text-lg uppercase mb-4'>Tid: {formatGameTime(gameTime)}</p>
            <img src={pictures[wrongLetters.length]} alt="Hangman" className='w-1/2 mx-auto'/>
            <div className="mb-4">
              {wrongLetters.length === 0 ? (
                <p className='text-center font-bold text-2xl uppercase'>&nbsp;</p>
              ) : (
                <p className='text-center font-bold text-2xl uppercase'>{wrongLetters.join(' ')}</p>
              )}
            </div>
          </div>
          <div className="md:mt-36 w-full">
            <div className="mb-4">
              <p className='text-center font-bold text-2xl uppercase'>{word.split('').map(letter => rightLetters.includes(letter) ? letter : '_').join(' ')}</p>
            </div>
            <div>
              {keyMap.map((row, i) => (
                <div key={i} className='flex justify-center gap-1 mb-1'>
                  {row.map(key => (
                    <button key={key.key} onClick={() => handleGuess(key.key)}
                    className={classNames('border border-black bg-white uppercase rounded-md py-1.5 w-1/12', {
                      '!bg-red-300': wrongLetters.includes(key.key),
                      '!bg-green-300': rightLetters.includes(key.key)
                    })}>
                      {key.key}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
